import React, { useEffect, useState } from "react"
import Layout from "../../components/layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "./seoLocal.scss"
import BlockExperience from "../../components/blockExperience/BlockExperience"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import Seo from "../../components/seo/seo"
import links from "../../utils/links"
import config from "../../utils/config"
import BlockHeroDiagnostic from "../../components/blockHeroDiagnostic/BlockHeroDiagnostic"
import tracking from "../../utils/tracking"

const seoLocal = ({ data, pageContext }) => {
  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])
  const baseUrlCms = config.URL_CMS
  const allNodePageNotreReseauPar = pageContext.link?.ville
    ? data.allNodePageNotreReseauParVille.edges[0].node
    : data.allNodePageNotreReseauParRegion.edges[0].node

  var rvtitre = allNodePageNotreReseauPar.field_rvtitre.value.replace("<p>","").replace("</p>","")
  const regex = /(.+\s(?:à|en|au))\s(.+)/;
  const correspondance1 = rvtitre.match(regex);
  const correspondance2 = rvtitre.match(regex);

  if (correspondance1 && correspondance1.length === 3) {
    var rvtitre_title = correspondance1[1] + " "
    var rvtitre_content = correspondance1[2]
  }
  else if (correspondance2 && correspondance2.length === 3){
    var rvtitre_title = correspondance2[1] + " "
    var rvtitre_content = correspondance2[2]
  }
  

  const breadcrumb = pageContext.link?.ville
    ? [
        {
          name: "Accueil",
          url: links.getLink("home") + campagne,
        },
        {
          name: "Notre réseau",
          url: links.getLink("reseau") + campagne,
        },
        {
          name: pageContext.region,
          url: links.getLink("reseau") + pageContext.link.region + "/" + campagne,
        },
        {
          name: pageContext.ville,
          url:
            links.getLink("reseau") +
            pageContext.link.region +
            "/" +
            pageContext.link.ville + (isTherecampagne ? "/" + campagne : ""),
        },
      ]
    : [
        {
          name: "Accueil",
          url: links.getLink("home") + campagne,
        },
        {
          name: "Notre réseau",
          url: links.getLink("reseau") + campagne,
        },
        {
          name: pageContext.region,
          url: links.getLink("reseau") + pageContext.link.region + "/" + campagne,
        },
      ]

  const seoPage = {
    title: allNodePageNotreReseauPar.title,
    description: allNodePageNotreReseauPar.field_meta_description,
  }

  useEffect(() => {
    /** Tracking */
    tracking.track("typeTemplate", "localite")
    tracking.track("page_name", seoPage.title + (isTherecampagne ? "/" + campagne : ""))
    tracking.track("categories", "telesurveillance")
    tracking.trackInit()
  }, [])

  const formatedParagrapheValue = (paragraphe, index) => {
    let t = document.createElement("div")
    let divImg = document.createElement("div")
    let divText = document.createElement("div")
    t.className = "paragraphe"
    divImg.className = "img"
    divText.className = "text"
    t.innerHTML = paragraphe
    if (t) {
      const getAllP = t.querySelectorAll("p")
      for (let i = 0; i < getAllP.length; i++) {
        if (getAllP[i].querySelector("img")) {
          divImg.innerHTML = getAllP[i].innerHTML
            .replace("<span>", "")
            .replace("</span>", "")
          getAllP[i].parentElement?.replaceChild(divImg, getAllP[i])
        }
      }
      for (let i = 0; i < t.children.length; i++) {
        if (t.children[i].tagName === "P") {
          let p = document.createElement("p")
          p.innerHTML = t.children[i].innerHTML
          divText.appendChild(p)
        }
        if (t.children[i].tagName === "H2") {
          let h2 = document.createElement("h2")
          h2.innerHTML = t.children[i].innerHTML
          divText.appendChild(h2)
        }
      }

      if (t.children[0].tagName !== "DIV") {
        t.children[0].replaceWith(divText)
      }
      if (t.children[t.children.length - 1].tagName !== "DIV") {
        t.children[t.children.length - 1].replaceWith(divText)
      }
      let childrenArr = [...t.children]
      childrenArr.forEach(el => {
        if (el.tagName === "P") {
          t.removeChild(el)
        }
        if (el.tagName === "H2") {
          t.removeChild(el)
        }
      })
      const getAllImgs = t.querySelectorAll("img")
      if (getAllImgs.length > 0) {
        ;[...getAllImgs].forEach(el => {
          const url = new URL(el.src)
          if (url) {
            const path = url.pathname

            if (path) {
              el.src = baseUrlCms + path
            }
          }
        })
      }
    }
    return t
  }

  // const dataHeroDiagnostic = pageContext.dataHeroDiagnostic

  return (
    <Layout isViewPromoOnHeader={false}>
      <Seo
        title={seoPage.title}
        description={seoPage.description}
        linkCanonical={`${pageContext.linkCanonical}/`}
      />
      <div className="bc-2 d-f fd-c ">
        <Breadcrumb data={breadcrumb} addStyle={"p-1"} />
        <span className={"fs-28 d-f jc-c ai-c pb-6 px-md-3 px-md-3 c-4 ff-gb"}>
          Nexecur en France
        </span>
      </div>
      <div className="title wrapper d-f fd-c ta-c jc-c ai-c mt-8 mt-md-5">
        <div className="rvtitre-block" dangerouslySetInnerHTML={{
              __html: "<h1 className=\"ff-gb rvtitre\">" + rvtitre_title + "<span className=\"ff-gb\">" + rvtitre_content + "</span>" + "</div>",
            }}>
        </div>
        <p className="ff-gbook" dangerouslySetInnerHTML={{__html: allNodePageNotreReseauPar.field_sous_titre_region_ville}}></p>
      </div>
      <div className="hero d-f fd-r jc-sb ai-c p-r py-8 py-md-5">
        <div
          className="text wrapper"
          dangerouslySetInnerHTML={{
            __html: allNodePageNotreReseauPar.field_hero.value
              .replace("<span>", "")
              .replace("</span>", ""),
          }}
        ></div>
        <div className="map">
          <GatsbyImage
            image={
              allNodePageNotreReseauPar.relationships.field_position_map
                .relationships.field_image_carte.localFile.childrenImageSharp[0]
                .gatsbyImageData
            }
            alt={
              allNodePageNotreReseauPar.relationships.field_position_map
                .field_image_carte.alt
            }
          />
        </div>
      </div>
      <div className="container-local py-8 py-md-5">
        <div className="content wrapper">
          <div
          className={"paragraphe-container d-f fd-r jc-sb ai-c"}
          dangerouslySetInnerHTML={{__html: pageContext.formattedParagraphe1}}
          >
          </div>
          <div
          className={"paragraphe-container d-f fd-r jc-sb ai-c"}
          dangerouslySetInnerHTML={{__html: pageContext.formattedParagraphe2}}
          >
          </div>
          {/*allNodePageNotreReseauPar.field_paragraphe_notre_reseau.map(
            (paragraph, index) => {
              return (
                <div
                  key={index}
                  className={"paragraphe-container d-f fd-r jc-sb ai-c"}
                  ref={ref =>
                    ref?.appendChild(formatedParagrapheValue(paragraph.value))
                  }
                ></div>
              )
            }
          )*/}
        </div>
      </div>
      {/* <BlockHeroDiagnostic data={dataHeroDiagnostic} /> */}
      <BlockExperience />
    </Layout>
  )
}
export const query = graphql`
  query ($region: String, $ville: String) {
    allNodePageNotreReseauParRegion(
      filter: { field_nom_de_la_region: { eq: $region } }
    ) {
      edges {
        node {
          title
          field_meta_description
          field_sous_titre_region_ville
          field_rvtitre {
            value
          }
          field_hero {
            value
          }
          field_paragraphe_notre_reseau {
            value
          }
          relationships {
            field_position_map {
              field_image_carte {
                alt
              }
              relationships {
                field_image_carte {
                  localFile {
                    childrenImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allNodePageNotreReseauParVille(
      filter: { field_nom_de_la_ville: { eq: $ville } }
    ) {
      edges {
        node {
          title
          field_meta_description
          field_sous_titre_region_ville
          field_rvtitre {
            value
          }
          field_hero {
            value
          }
          field_paragraphe_notre_reseau {
            value
          }
          relationships {
            field_position_map {
              name
              field_image_carte {
                alt
              }
              relationships {
                field_image_carte {
                  localFile {
                    childrenImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default seoLocal
